<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Avaliação Nutricional</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.fazDivisaoDasVacasPorProducaoDeLeite"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.fazDivisaoDasVacasPorProducaoDeLeite == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="
              relatorio.fazDivisaoDasVacasPorProducaoDeLeite == 'cancel'
            "
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{ 'Faz divisão das vacas por produção de leite?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.fazAcompanhamentoMensal"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.fazAcompanhamentoMensal == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="relatorio.fazAcompanhamentoMensal == 'cancel'"
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{ 'Faz acompanhamento mensal da produção de leite?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.aFazendaPossuiVagaoForrageiro"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.aFazendaPossuiVagaoForrageiro == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="relatorio.aFazendaPossuiVagaoForrageiro == 'cancel'"
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{ 'A fazenda possui vagão forrageiro?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.oVagaoPossuiBalanca"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.oVagaoPossuiBalanca == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="relatorio.oVagaoPossuiBalanca == 'cancel'"
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{ 'O Vagão possui balança?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.fazOMonitoramentoDaMateriaSecaComFrequencia"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.fazOMonitoramentoDaMateriaSecaComFrequencia == 'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="
              relatorio.fazOMonitoramentoDaMateriaSecaComFrequencia == 'cancel'
            "
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{ 'Faz o monitoramento da Matéria Seca com frequencia?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.fazAnaliseBromatologicaDoVolumosoComFrequencia"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.fazAnaliseBromatologicaDoVolumosoComFrequencia ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="
              relatorio.fazAnaliseBromatologicaDoVolumosoComFrequencia ==
              'cancel'
            "
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{ 'Faz análise bromatológica do volumoso com frequencia?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.aSilagemDeMilhoSorgoCanaCapimEstaComAparenciaBoa"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.aSilagemDeMilhoSorgoCanaCapimEstaComAparenciaBoa ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="
              relatorio.aSilagemDeMilhoSorgoCanaCapimEstaComAparenciaBoa ==
              'cancel'
            "
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{
              'A silagem de milho/sorgo/cana/capim está com aparência boa (tamanho de partícula, sem a presença de mofo, temperatura, compactação, perdas de silo superficiais)?'
            }}
          </span>
        </v-col>
        <v-col v-if="relatorio.osInsumoes" cols="12" class="col-bordered">
          <span
            v-if="relatorio.osInsumoes == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="relatorio.osInsumoes == 'cancel'"
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{
              'Os insumos (Soja, milho, minerais) estão armazenados de maneira correta e em local limpo e organizado?'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.ultilizaUmaSegundaFonteDeForragemNaMesmaDieta"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.ultilizaUmaSegundaFonteDeForragemNaMesmaDieta ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="
              relatorio.ultilizaUmaSegundaFonteDeForragemNaMesmaDieta ==
              'cancel'
            "
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{ 'Utiliza uma segunda fonte de forragem na mesma Dieta?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.existeSelecaoDeIngredientesDaDietaNoCochopelasVacas"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.existeSelecaoDeIngredientesDaDietaNoCochopelasVacas ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="
              relatorio.existeSelecaoDeIngredientesDaDietaNoCochopelasVacas ==
              'cancel'
            "
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{
              'Existe seleção de ingredientes da dieta no cocho pelas vacas?'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.monitoraATaxaDeSobraDeComidaNoCocho"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.monitoraATaxaDeSobraDeComidaNoCocho == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="
              relatorio.monitoraATaxaDeSobraDeComidaNoCocho == 'cancel'
            "
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{ 'Monitora a taxa de sobra de comida no cocho?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.aDistribuicaoDaDietaEstaSendoRealizadaDeFormaAdequada"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.aDistribuicaoDaDietaEstaSendoRealizadaDeFormaAdequada ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span
            v-else-if="
              relatorio.aDistribuicaoDaDietaEstaSendoRealizadaDeFormaAdequada ==
              'cancel'
            "
            class="mdi mdi-close-outline"
          ></span>
          <span class="value-title">
            {{
              'A distribuição da dieta está sendo realizada de forma adequada? (Horário, distribuição, rotina)?'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.escoreCorporalEstaAdequadoNoPeriodoDeTransicao"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.escoreCorporalEstaAdequadoNoPeriodoDeTransicao ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Escore corporal está adequado no Período de Transição?' }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.oNitrogenioUreicoDoLeiteNULMedioDosUltimos3MesesEstaAdequado
          "
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.oNitrogenioUreicoDoLeiteNULMedioDosUltimos3MesesEstaAdequado ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'O Nitrogênio Ureico do leite(NUL) médio dos últimos 3 meses está adequado?'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.possuiOacompanhamentoMensalDeUmNutricionista"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.possuiOacompanhamentoMensalDeUmNutricionista ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Possui o acompanhamento Mensal de um Nutricionista?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.apresentaGraosDeMilhoInteirosNasFezes"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.apresentaGraosDeMilhoInteirosNasFezes == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Apresenta grãos de milho inteiros nas fezes?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.temCostumeDeTirarAsFaixasComMofoNoSilo"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.temCostumeDeTirarAsFaixasComMofoNoSilo == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Tem costume de tirar as faixas com mofo no silo?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.temOcostumeDeLimparAsSobrasDoSilo"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.temOcostumeDeLimparAsSobrasDoSilo == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Tem o costume de limpar as sobras do silo?' }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.possuiRotinaDePesarMediaAQuantidadeDeSiloAntesdeOfertarAosAnimais
          "
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.possuiRotinaDePesarMediaAQuantidadeDeSiloAntesdeOfertarAosAnimais ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Possui a rotina de pesar/medir a quantidade de silo antes de ofertar aos animais?'
            }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.aRetiradaDoPainelDoSiloEstaSendoFeitaDeManeiraAdequada
          "
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.aRetiradaDoPainelDoSiloEstaSendoFeitaDeManeiraAdequada ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A retirada do painel do silo está sendo feita de maneira adequada?'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.possuiDietaAnotadaEAfixadaEmLocalDeFacilAcesso"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.possuiDietaAnotadaEAfixadaEmLocalDeFacilAcesso ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Possui a dieta anotada e afixada em local de fácil acesso?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.semprePesaARacaoAntesDeFornecerAosAnimais"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.semprePesaARacaoAntesDeFornecerAosAnimais == 'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Sempre pesa a ração antes de fornecer aos animais?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.FDNdaDietaEstAcimaDe25DaMS"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.FDNdaDietaEstAcimaDe25DaMS == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'FDN da dieta está acima de 25% da MS?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.AmidoDaDietaEstEntre25A30DaMS"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.AmidoDaDietaEstEntre25A30DaMS == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Amido da dieta está entre 25 a 30% da MS?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.PBdaDietaEstEntre15E17DaMS"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.PBdaDietaEstEntre15E17DaMS == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'PB da dieta está entre 15 e 17% MS?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.CNFdaDietaEstEntre35A40DaMS"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.CNFdaDietaEstEntre35A40DaMS == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'CNF da dieta está entre 35 a 40% da MS?' }}
          </span>
        </v-col>
        <span class="pa-3"></span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
