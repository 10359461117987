var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.relatorio.princiapaisInconformidadesImages != null &&
    _vm.relatorio.princiapaisInconformidadesImages.length
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Imagens em anexo")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.princiapaisInconformidadesImages != null &&
          _vm.relatorio.princiapaisInconformidadesImages.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens das principais inconformidades ")]),_c('br'),_vm._l((_vm.relatorio.princiapaisInconformidadesImages),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }