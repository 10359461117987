/* eslint-disable no-unused-vars */
import moment from 'moment'
import _ from 'underscore'
import { assosiationLabel } from './dashboard'
export const headersEfficiency = [
  {
    text: 'Produtor',
    value: 'fazenda.produtor.Nome',
  },
  {
    text: 'Propriedade',
    value: 'fazenda.pessoajuridica.NomeFantasia',
  },
  {
    text: 'Laticinio',
    value:
      'fazenda.fazenda_agroindustria[0].agroindustria.pessoajuridica.NomeFantasia',
  },
  {
    text: 'Número',
    value: 'numeroVisita',
  },
  {
    text: 'Data',
    value: 'dataAtendimento',
  },
  {
    text: 'Hora Inicio',
    value: 'horaInicio',
  },
  {
    text: 'Termino',
    value: 'horaTermino',
  },
  {
    text: 'CPP Antes da visita',
    value: 'cppMedia',
  },
  {
    text: 'CPP Depois da visita',
    value: 'finalizedCpp',
  },
  {
    text: '% Redução',
    value: 'reduction',
  },
  {
    text: 'Efetividade',
    value: 'efectiveness',
  },
]

export const dataEfficiency = data => {
  return data.map(item => {
    if (!item.finalizedCpp) {
      return {
        ...item,
        reduction: 0,
        efectiveness: 'SEM ANÁLISE',
      }
    } else {
      const reduction = (item.cppMedia - item.finalizedCpp) / item.cppMedia

      let effective = reduction * 100 > 30 ? 'EFETIVO' : 'NÃO EFETIVO'

      return {
        ...item,
        reduction: (reduction * 100).toFixed(2) + '%',
        efectiveness: effective,
      }
    }
  })
}

export const headersEfficiencyByVisits = date => {
  const abreviatedYear = moment(date).format('YY')
  const yearsLabels = []

  assosiationLabel.forEach(item => {
    const param = item.value
    const value = item.text + `/${abreviatedYear}`

    yearsLabels.push({
      text: value,
      value: param,
      align: 'start',
    })
  })

  return [
    {
      text: '',
      width: '200px',
      value: 'label',
    },
    ...yearsLabels,
  ]
}

export const dataEfficiencyByVisits = data => {
  const visits = []

  const grubyYears = _.groupBy(data, ({ dataAtendimento }) => {
    return new Date(dataAtendimento).getFullYear()
  })

  Object.keys(grubyYears).map(year => {
    const {
      lineVisitEfficiency,
      lineVisitRealized,
    } = builderDataEfficiencyByVisitsTable(grubyYears[year])

    const result = []

    lineVisitEfficiency['label'] = 'Visitas Efetivas (Redução > 30% )'
    lineVisitRealized['label'] = 'Visitas realizadas'

    result.push(lineVisitRealized)
    result.push(lineVisitEfficiency)

    visits.push({
      year: year,
      content: result,
      headers: headersEfficiencyByVisits(data[0].dataAtendimento),
    })
  })

  return visits
}

const builderDataEfficiencyByVisitsTable = data => {
  const lineVisitRealized = {}
  const lineVisitEfficiency = {}

  const groupByMonth = _.groupBy(data, ({ dataAtendimento }) => {
    return new Date(dataAtendimento).getMonth()
  })

  Object.keys(groupByMonth).map(month => {
    const realized = groupByMonth[month].length

    const monthName = assosiationLabel[month].value

    lineVisitRealized[monthName] = realized

    const efficiency = groupByMonth[month].filter(item => {
      if (!item.finalizedCpp) return null

      const reduction = (item.cppMedia - item.finalizedCpp) / item.cppMedia

      if (reduction * 100 > 30) {
        return item
      }

      return null
    })

    lineVisitEfficiency[monthName] = efficiency.length
  })

  return {
    lineVisitRealized,
    lineVisitEfficiency,
  }
}

export const dataEfficiencyByAVG = data => {
  const visits = []

  const grubyYears = _.groupBy(data, ({ dataAtendimento }) => {
    return new Date(dataAtendimento).getFullYear()
  })

  Object.keys(grubyYears).map(year => {
    const {
      lineVisitEfficiency,
      lineVisitRealized,
      produtoresIN76,
    } = builderDataEfficiencyByAVGTable(grubyYears[year])

    const result = []

    lineVisitEfficiency['label'] = 'CPP Depois da visita'
    lineVisitRealized['label'] = 'CPP Antes da visita'
    produtoresIN76['label'] = 'N° de produtores na IN 76'

    result.push(lineVisitRealized)
    result.push(lineVisitEfficiency)
    result.push(produtoresIN76)

    const reduceLineVisit = {}

    Object.keys(lineVisitEfficiency).forEach(key => {
      if (!lineVisitEfficiency[key]) {
        reduceLineVisit[key] = '0%'
      } else {
        reduceLineVisit[key] =
          (
            ((lineVisitRealized[key] - lineVisitEfficiency[key]) /
              lineVisitRealized[key]) *
            100
          ).toFixed(2) + '%'
      }
    })

    reduceLineVisit['label'] = '% Redução'
    reduceLineVisit['class'] = 'blue-row'

    result.push(reduceLineVisit)

    visits.push({
      year: year,
      content: result,
      headers: headersEfficiencyByVisits(data[0].dataAtendimento),
    })
  })

  return visits
}

export const builderDataEfficiencyByAVGTable = data => {
  const lineVisitRealized = {}
  const lineVisitEfficiency = {}
  const produtoresIN76 = {}

  const groupByMonth = _.groupBy(data, ({ dataAtendimento }) => {
    return new Date(dataAtendimento).getMonth()
  })

  Object.keys(groupByMonth).map(month => {
    const realized = groupByMonth[month].reduce((acc, item) => {
      return acc + Number(item.cppMedia)
    }, 0)

    const monthName = assosiationLabel[month].value

    lineVisitRealized[monthName] = (
      realized / groupByMonth[month].length
    ).toFixed(2)

    let finalizedCppAvg = 0

    groupByMonth[month].forEach(item => {
      if (!item.finalizedCpp) return null

      if (Number(item?.finalizedCpp) < 300) {
        if (!produtoresIN76[monthName]) produtoresIN76[monthName] = 0

        produtoresIN76[monthName] += 1
      } else {
        if (!produtoresIN76[monthName]) produtoresIN76[monthName] = 0
      }

      if (Number(item.finalizedCpp)) {
        if (!lineVisitEfficiency[monthName]) lineVisitEfficiency[monthName] = 0

        lineVisitEfficiency[monthName] += Number(item.finalizedCpp)
        finalizedCppAvg++
      }
    })

    if (lineVisitEfficiency[monthName]) {
      lineVisitEfficiency[monthName] = (
        lineVisitEfficiency[monthName] / finalizedCppAvg
      ).toFixed(2)
    }

    finalizedCppAvg = 0
  })

  return {
    lineVisitRealized,
    lineVisitEfficiency,
    produtoresIN76,
  }
}
