<template>
  <div>
    <base-loading v-if="loadingFilter" />
    <v-row v-else align="center" justify="center" class="px-10">
      <v-col class="pa-1" cols="2" sm="2" md="2">
        <v-menu
          v-model="menuDataIni"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(form.dataInicio).format('DD/MM/YYYY')"
              label="Data Inicial"
              prepend-icon="mdi-calendar"
              color="teal"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.dataInicio"
            style="margin: 0px"
            :max="form.dataFim"
            color="teal"
            locale="pt-br"
            @input="menuDataIni = false"
          />
        </v-menu>
      </v-col>
      <v-col class="pa-1" cols="2" sm="2" md="2">
        <v-menu
          v-model="menuDataFim"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(form.dataFim).format('DD/MM/YYYY')"
              color="teal"
              label="Data Final"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.dataFim"
            :min="form.dataInicio"
            :max="dataLimite"
            color="teal"
            locale="pt-br"
            @input="menuDataFim = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="form.produtor"
          autocomplete="autocomplete_off_hack_xfr4!k"
          label="Produtor"
          :items="produtores"
          item-text="name"
          item-value="id"
          clearable
          required
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="form.consultor"
          autocomplete="autocomplete_off_hack_xfr4!k"
          label="Consultor"
          :items="consultor"
          item-text="name"
          item-value="id"
          clearable
          required
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="form.agroindustria"
          autocomplete="autocomplete_off_hack_xfr4!k"
          label="Agroindustria"
          :items="agroindustrias"
          item-text="name"
          item-value="id"
          clearable
          required
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="form.fazenda"
          autocomplete="autocomplete_off_hack_xfr4!k"
          label="Fazenda"
          :items="fazendas"
          item-text="name"
          item-value="id"
          clearable
          required
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="form.activeProducers"
          autocomplete="autocomplete_off_hack_xfr4!k"
          label=""
          :items="activeProducersList"
          item-text="name"
          item-value="id"
          clearable
          required
        />
      </v-col>
      <v-col class="pa-2" cols="2" sm="2" md="2">
        <v-row justify="end" align="center">
          <v-btn
            style="color: white; border-radius: 8px; margin-left: 40%"
            color="green darken-1"
            @click="searchDashboard()"
          >
            <span style="font-size: 16px; font-weight: 600">Buscar</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import filterService from '../../../services/api/filter'
import { activeProducersList } from '../../../utils/dashboard/dashboard'

export default {
  name: 'FilterDashboard',

  emits: ['search'],

  props: {
    farms: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeProducersList,
      loadingFilter: true,

      produtores: [{ name: 'Carregando', id: 0 }],
      consultor: [{ name: 'Carregando', id: 0 }],
      agroindustrias: [{ name: 'Carregando', id: 0 }],
      fazendas: [{ name: 'Carregando', id: 0 }],

      form: {
        produtor: '',
        consultor: '',
        agroindustria: '',
        fazenda: '',
        activeProducers: activeProducersList[0],
        dataInicio: this.moment()
          .subtract(1, 'months')
          .toISOString(true)
          .substr(0, 10),
        dataFim: this.moment().toISOString(true).substr(0, 10),
      },

      searching: true,
      dataLimite: this.moment().toISOString(true).substr(0, 10),
      menuDataIni: false,
      menuDataFim: false,
    }
  },

  watch: {
    farms: {
      handler: function (farms) {
        this.loadFilter(farms)
      },
      deep: true,
    },
  },

  created() {
    this.loadingFilter = true
  },

  methods: {
    searchDashboard() {
      this.searching = true
      this.loadingFilter = true

      this.$emit('search', {
        ...this.form,
      })
    },

    async loadFilter(farms) {
      this.loadingFilter = true

      try {
        const data = await filterService.list({
          farms,
          params: {
            produtor: true,
            consultor: true,
            agroindustria: true,
          },
        })

        if (
          !data.agroindustrias.length &&
          !data.consultores.length &&
          !data.produtores.length &&
          !data.fazendas.length
        ) {
          this.Toast().fire({
            icon: 'warning',
            title: 'Não há dados para exibir',
          })

          this.form = {
            produtor: '',
            consultor: '',
            agroindustria: '',
            fazenda: '',
            activeProducers: activeProducersList[0],
            dataInicio: this.moment()
              .subtract(1, 'months')
              .toISOString(true)
              .substr(0, 10),
            dataFim: this.moment().toISOString(true).substr(0, 10),
          }

          return
        }

        data.agroindustrias.unshift({ name: '-- Selecione --', id: '0' })
        data.consultores.unshift({ name: '-- Selecione --', id: '0' })
        data.produtores.unshift({ name: '-- Selecione --', id: '0' })
        data.fazendas.unshift({ name: '-- Selecione --', id: '0' })

        this.agroindustrias = data.agroindustrias
        this.consultor = data.consultores
        this.produtores = data.produtores
        this.fazendas = data.fazendas
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar filtros: ' + error.message,
        })
      } finally {
        this.loadingFilter = false
      }
    },
  },
}
</script>
