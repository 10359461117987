<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog v-model="dialog" max-width="800">
        <v-card>
          <v-toolbar color="primary" dark>{{ title }}</v-toolbar>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th id="view">Visualizar</th>
                    <th id="fazenda" class="text-left">Fazenda</th>
                    <th id="produtor" class="text-left">Produtor</th>
                    <th id="consultor" class="text-left">Consultor</th>
                    <th id="dataAtendimento" class="text-left">
                      Data do atendimento
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      <router-link
                        :to="{
                          path: `/${$user.get().role}/relatorios/${reportType}`,
                          query: { id: item.id },
                        }"
                        target="_blank"
                      >
                        <v-btn color="primary" icon>
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </router-link>
                    </td>
                    <td>{{ item.fazenda.pessoajuridica.NomeFantasia }}</td>
                    <td>{{ item.fazenda.produtor.Nome }}</td>
                    <td>{{ item.fazenda.consultor.Nome }}</td>
                    <td>
                      {{ moment(item.dataAtendimento).format('DD/MM/YYYY') }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog = false">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DialogInconformidades',

  props: {
    value: Boolean,

    title: {
      type: String,
      default: 'Inconformidades',
    },

    items: {
      type: Array,
      default: () => [],
    },

    reportType: {
      type: String,
      default: 'cpp',
    },
  },

  data: () => ({
    moment,
    dialog: false,
  }),

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.dialog = value
      },
    },
    dialog(value) {
      this.$emit('input', value)
    },
  },
}
</script>
