import HtmlToCanvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../../env'
import { monthsNames } from '../../../utils/dashboard/dashboard'

export const MARGIN = 25
export const REALSTARTY = 95

export const CONFIG = {
  startY: REALSTARTY,
  startX: MARGIN,
  smallGutterY: 12,
  smallGutterX: 6,
  line: 0.3,
  margin: MARGIN,
  lineColor: '#000',
  boxHeight: 27,
  smallBoxHeight: 18,
  gutterX: 14,
  gutterY: 23,
  smallFontSize: 6.5,
  fontSize: 10,
  largefontSize: 16,
  mediumFontSize: 12,
  logo: 'img/' + `${ENV_APP.imgFileLogin}`,
  bodyStarY: REALSTARTY + 25 * 8.5,
}

export class DashboardBase {
  constructor({ title, imgs, imgsTitles, config = {} }) {
    if (!title) throw new Error('Informe o título do relatório')
    if (!imgs) throw new Error('Informe as imagens do relatório')
    if (!imgsTitles) throw new Error('Informe os títulos das imagens')

    this.title = title
    this.imgs = imgs
    this.imgsTitles = imgsTitles

    this.jsPDF = new JsPDF({
      format: 'a4',
      compress: false,
      unit: 'pt',
    })

    this.config = {
      ...CONFIG,
      tableLimit: this.jsPDF.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: this.jsPDF.internal.pageSize.height - MARGIN,
      ...config,
    }
  }

  static baseboardTitle() {
    const now = new Date()
    const str =
      'Impresso em ' +
      now.getDate() +
      ' de ' +
      monthsNames[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      moment(now).format('DD/MM/YYYY')

    return str
  }

  header() {
    const logo = new Image()

    logo.src = this.config.logo

    this.jsPDF.addImage(
      logo,
      'PNG',
      this.config.margin,
      this.config.margin,
      70,
      55,
    )

    this.jsPDF
      .setFontSize(this.config.largefontSize)
      .setFont('helvetica', 'bold')

    const widthOfString = this.jsPDF.getTextWidth(this.title)

    this.jsPDF.text(
      this.title,
      this.config.tableLimit / 2 - widthOfString / 2 + this.config.margin,
      this.config.margin + 30,
    )

    this.jsPDF.setFontSize(this.config.fontSize).setFont('helvetica', 'normal')
  }

  baseboardPrint({ cursor, text }) {
    const { boxHeight, heightLimit, margin } = this.config
    const docWidth = this.jsPDF.internal.pageSize.width
    const date = DashboardBase.baseboardTitle()
    const texto =
      date + '       ' + `${ENV_APP.relUri}` + '        Pag. ' + text

    if (cursor + boxHeight * 2 > heightLimit) {
      this.jsPDF.addPage()
      cursor = margin
    }

    this.jsPDF.text(texto, docWidth / 2, heightLimit + 10, 'center')
  }

  async render() {
    let page = 1

    for (const [index, img] of this.imgs.entries()) {
      let text = this.imgsTitles[index]

      const canvas = await HtmlToCanvas(img)

      this.header()
      const dataURL = canvas.toDataURL('image/png')

      this.jsPDF
        .setFont('helvetica', 'bold')
        .setFontSize(this.config.mediumFontSize)
        .text(text, this.config.startX, this.config.startY)
        .setFont('helvetica', 'normal')
        .setFontSize(this.config.fontSize)

      this.jsPDF.addImage(
        dataURL,
        'PNG',
        this.config.startX,
        this.config.startY + this.config.boxHeight,
        550,
        300,
      )

      // Ao adicionar duas imagens por páginas é necessário adicionar uma página
      if (page % 2 === 0) {
        this.jsPDF.addPage()
        page -= 2
        this.config.startY -= 350
      } else {
        this.config.startY += 350
      }

      page++
    }

    this.writeFooter()
  }

  writeFooter() {
    let cursor = 0
    const pageCount = this.jsPDF.internal.getNumberOfPages()

    for (let i = 1; i <= pageCount; i++) {
      this.jsPDF.setPage(i)
      this.baseboardPrint({
        cursor: cursor + this.config.boxHeight,
        text: String(i) + ' de ' + String(pageCount),
      })
    }
  }

  async save({ filename = '' }) {
    if (!filename) {
      const name = this.title.replace(/\s/g, '-').toLowerCase()
      filename = `${name}-${new Date().getTime()}.pdf`
    }

    const result = this.jsPDF.save(filename, { returnPromise: true })

    return result
  }
}
