import Toast from '../../plugins/toast'

export const typeValidation = status => {
  if (status === 0) {
    return 'Não validado'
  } else if (status === 1) {
    return 'Válido'
  } else if (status === 2) {
    return 'Inconsistente'
  } else if (status === 3) {
    return 'Justificado'
  }

  return '-'
}

export const storageReportAvaliable = () => {
  const result = localStorage.getItem('reportAvaliable')

  if (result) {
    Toast().fire({
      icon: 'error',
      title: 'Já existe uma geração de relatório em andamento!',
    })

    return false
  }

  return true
}

export const exportReport = async ({
  report,
  exportReport,
  exportReportDeprecated = null,
  layout = null,
}) => {
  if (!report) {
    return Toast().fire({
      icon: 'error',
      title:
        'Não foi possivel gerar o relatório, atualize a página e tente novamente',
    })
  }

  try {
    if (report.Type == 'report') {
      await exportReport(report, layout)
    } else {
      await exportReportDeprecated(report)
    }

    return true
  } catch (err) {
    localStorage.removeItem('reportAvaliable')
    Toast().fire({
      icon: 'error',
      title: 'Não foi possivel gerar o relatório!',
    })
  }
}

export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
