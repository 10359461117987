<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Melhoramento Genético</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.possuiAlgunsDosProgramasDeMelhoramentoGenTico"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.possuiAlgunsDosProgramasDeMelhoramentoGenTico ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Possui algum plano genético na propriedade?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.aPropriedadeUtilizaInseminaOArtificial"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.aPropriedadeUtilizaInseminaOArtificial == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A propriedade utiliza inseminação artificial?' }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.aFazendaTemCostumeDeUtilizarDentroDoSeuPlanejamentoGenTicoTourosComAptidOParaSOlidosPositivo
          "
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.aFazendaTemCostumeDeUtilizarDentroDoSeuPlanejamentoGenTicoTourosComAptidOParaSOlidosPositivo ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A fazenda tem costume de utilizar dentro do seu planejamento genético touros com aptidão para sólidos positivo?'
            }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
