<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center"> Relatório Sólidos </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGerais :relatorio="relatorio" />
      <IndicadoresQualidade :relatorio="relatorio" />
      <AvaliacaoNutricional :relatorio="relatorio" />
      <MelhoramentoGenetico :relatorio="relatorio" />
      <AvaliacaoAmbiente :relatorio="relatorio" />
      <NecessidadeInsumos :relatorio="relatorio" />
      <ListagemDeinconformidades :relatorio="relatorio" />
      <PlanosDeAcao :relatorio="relatorio" />
      <ProximaVisita :relatorio="relatorio" />
      <ImagensAnexo :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn
            color="primary"
            :loading="dialogProgressPDF"
            :disabled="dialogProgressPDF"
            @click="
              () => {
                exportRel(relatorio, true)
              }
            "
          >
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import exportPDF from '../../../../services/reports/RelatorioPDFSolidos'
import { exportReport, timeout } from '../../../../utils/report'
import AvaliacaoAmbiente from './AvaliacaoAmbiente.vue'
import AvaliacaoNutricional from './AvaliacaoNutricional'
import ImagensAnexo from './ImagensAnexo'
import IndicadoresQualidade from './IndicadoresQualidade'
import InformacoesGerais from './InformacoesGerais'
import ListagemDeinconformidades from './ListagemDeinconformidades.vue'
import MelhoramentoGenetico from './MelhoramentoGenetico'
import NecessidadeInsumos from './NecessidadeInsumos.vue'
import PlanosDeAcao from './PlanoAcao.vue'
import ProximaVisita from './ProximaVisita'

export default {
  components: {
    MelhoramentoGenetico,
    IndicadoresQualidade,
    InformacoesGerais,
    AvaliacaoNutricional,
    ProximaVisita,
    AvaliacaoAmbiente,
    NecessidadeInsumos,
    ListagemDeinconformidades,
    PlanosDeAcao,
    ImagensAnexo,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      dialogProgressPDF: false,
      relatorio: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/solidos`,
      })
    } else {
      this.getRelatorio()
    }
  },
  methods: {
    timeout,
    exportPDF,
    getRelatorio() {
      this.api.get
        .relatorioStructure(this.codRelatorio, this.draft)
        .then(data => {
          this.relatorio = data
          this.loading = false
        })
    },
    async exportRel(relatorio, local) {
      if (!relatorio) relatorio = this.relatorio
      this.dialogProgressPDF = !!local

      await this.timeout(200)

      await exportReport({
        report: relatorio,
        exportReport: this.exportPDF,
      })

      this.dialogProgressPDF = false
    },
  },
}
</script>
