import ReportConstructor from '.'
import { ENV_APP } from '../../../../env'

export function baseBoard(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config

  const docWidth = doc.internal.pageSize.width

  const texto =
    ReportConstructor.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 7, 'center')
}
