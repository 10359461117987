import { colors } from './theme'

export function renderDatasetGraph(result) {
  let index = 0
  const data = []

  Object.entries(result).forEach(([key, value]) => {
    let tmp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    value.forEach(item => {
      tmp[item.month - 1] = parseInt(Number(item.avg))
    })

    data.push({
      data: tmp,
      label: key,
      backgroundColor: colors[index],
    })

    index++

    tmp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  })

  return data
}

export function renderDatasetTable(result) {
  const data = []

  result.forEach(item => {
    const tmp = {}

    tmp.year = item.label

    tmp.jan = parseInt(item.data[0]) ? parseInt(item.data[0]) : ' '
    tmp.fev = parseInt(item.data[1]) ? parseInt(item.data[1]) : ' '
    tmp.mar = parseInt(item.data[2]) ? parseInt(item.data[2]) : ' '
    tmp.abr = parseInt(item.data[3]) ? parseInt(item.data[3]) : ' '
    tmp.mai = parseInt(item.data[4]) ? parseInt(item.data[4]) : ' '
    tmp.jun = parseInt(item.data[5]) ? parseInt(item.data[5]) : ' '
    tmp.jul = parseInt(item.data[6]) ? parseInt(item.data[6]) : ' '
    tmp.ago = parseInt(item.data[7]) ? parseInt(item.data[7]) : ' '
    tmp.set = parseInt(item.data[8]) ? parseInt(item.data[8]) : ' '
    tmp.out = parseInt(item.data[9]) ? parseInt(item.data[9]) : ' '
    tmp.nov = parseInt(item.data[10]) ? parseInt(item.data[10]) : ' '
    tmp.dez = parseInt(item.data[11]) ? parseInt(item.data[11]) : ' '

    data.push(tmp)
  })

  return data
}

export function renderDispersion(result) {
  const data = result.map(item => {
    return {
      x: item.CPP,
      y: item.CCS,
      produtor: item.farm.produtor,
      propriedade: item.farm.propriedade,
    }
  })

  return data
}

export const monthShortLabel = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
]

export const assosiationLabel = [
  {
    text: 'Jan',
    value: 'jan',
  },
  {
    text: 'Fev',
    value: 'fev',
  },
  {
    text: 'Mar',
    value: 'mar',
  },
  {
    text: 'Abr',
    value: 'abr',
  },
  {
    text: 'Mai',
    value: 'mai',
  },
  {
    text: 'Jun',
    value: 'jun',
  },
  {
    text: 'Jul',
    value: 'jul',
  },
  {
    text: 'Ago',
    value: 'ago',
  },
  {
    text: 'Set',
    value: 'set',
  },
  {
    text: 'Out',
    value: 'out',
  },
  {
    text: 'Nov',
    value: 'nov',
  },
  {
    text: 'Dez',
    value: 'dez',
  },
]

export const activeProducersList = [
  {
    name: 'Todos',
    id: 0,
  },
  {
    name: 'Ativos',
    id: 1,
  },
  {
    name: 'Inativos',
    id: 2,
  },
]

export const monthsNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]
