var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Avaliação Ambiente (Conforto e Bem Estar)")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(_vm.relatorio.osAnimaisFicamAlojadosEmAmbienteQuePropicieConforto)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.osAnimaisFicamAlojadosEmAmbienteQuePropicieConforto ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Os animais ficam alojados em ambiente que propicie conforto?')+" ")])]):_vm._e(),(_vm.relatorio.oManejoDeCamaFreeStallCompostBarnEstAdequado)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.oManejoDeCamaFreeStallCompostBarnEstAdequado ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('O manejo de cama (Free stall /Compost barn) está adequado?')+" ")])]):_vm._e(),(
          _vm.relatorio.possuiSistemaDeResfriamentoAdequadoOsAnimaisSombritesAspersOEVentilaOSuficiente
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.possuiSistemaDeResfriamentoAdequadoOsAnimaisSombritesAspersOEVentilaOSuficiente ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Possui sistema de resfriamento adequado os animais (sombrites, aspersão e ventilação suficiente)?')+" ")])]):_vm._e(),(_vm.relatorio.aIncidNciaDeVacasMancandoAlta)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.aIncidNciaDeVacasMancandoAlta == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('A incidência de vacas mancando é alta?')+" ")])]):_vm._e(),(_vm.relatorio.possuiPeloMenos80DasVacasDeitadasApsElasSeAlimentarem)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.possuiPeloMenos80DasVacasDeitadasApsElasSeAlimentarem ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Possui pelo menos 80% das vacas deitadas após elas se alimentarem?')+" ")])]):_vm._e(),(_vm.relatorio.oEspaAmentoDeCochoEstAadequado)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.oEspaAmentoDeCochoEstAadequado == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('O Espaçamento de cocho está adequado?')+" ")])]):_vm._e(),(_vm.relatorio.possuiBebedouroNaSaDaDaOrdenha)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.possuiBebedouroNaSaDaDaOrdenha == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Possui Bebedouro na saída da ordenha?')+" ")])]):_vm._e(),(_vm.relatorio.aDisponibilidadeDeGuaParaOsAnimaisAdequada)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.aDisponibilidadeDeGuaParaOsAnimaisAdequada == 'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('A disponibilidade de água para os animais é adequada?')+" ")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }