var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Melhoramento Genético")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(_vm.relatorio.possuiAlgunsDosProgramasDeMelhoramentoGenTico)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.possuiAlgunsDosProgramasDeMelhoramentoGenTico ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Possui algum plano genético na propriedade?')+" ")])]):_vm._e(),(_vm.relatorio.aPropriedadeUtilizaInseminaOArtificial)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.aPropriedadeUtilizaInseminaOArtificial == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('A propriedade utiliza inseminação artificial?')+" ")])]):_vm._e(),(
          _vm.relatorio.aFazendaTemCostumeDeUtilizarDentroDoSeuPlanejamentoGenTicoTourosComAptidOParaSOlidosPositivo
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.aFazendaTemCostumeDeUtilizarDentroDoSeuPlanejamentoGenTicoTourosComAptidOParaSOlidosPositivo ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('A fazenda tem costume de utilizar dentro do seu planejamento genético touros com aptidão para sólidos positivo?')+" ")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }