<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Necessidade de Insumos</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.aPropriedadePossuiVolumosoDeQualidadeParaOAnoTodo"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.aPropriedadePossuiVolumosoDeQualidadeParaOAnoTodo ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A propriedade possui volumoso de qualidade para o ano todo?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.aPropriedadeFazCompraEstratGicaDeInsumos"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.aPropriedadeFazCompraEstratGicaDeInsumos == 'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A propriedade faz compra estratégica de insumos?' }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
