import moment from 'moment'

export default class ReportConstructor {
  constructor({ doc, config }) {
    this.doc = doc
    this.config = config
  }

  renderSectionTitle({ title, startX, startY }) {
    const { doc, config } = this

    doc.setFillColor(config.lineColor)

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(config.mediumFontSize)
      .text(title, startX, startY + 10)
      .setFont('helvetica', 'normal')
      .setFontSize(config.fontSize)
  }

  box({ startX, startY, line, boxHeight }) {
    const { doc, config } = this

    // line top
    doc.rect(startX, startY, config.tableLimit, line, 'F')

    // line bottom
    doc.rect(startX, startY, line, boxHeight, 'F')

    // line right
    doc.rect(startX + config.tableLimit, startY, line, boxHeight, 'F')

    // line left
    doc.rect(startX, startY + boxHeight, config.tableLimit, line, 'F')
  }

  addAnwserAndQuastion({ title, subtitle, startX, startY }) {
    const { doc, config } = this

    // title of box
    doc
      .setFont('helvetica', 'normal')
      .text(title, startX + config.smallGutterX, startY + config.smallGutterY)

    // subtitle of box
    doc
      .setFont('helvetica', 'bold')
      .text(
        subtitle ?? ' - ',
        startX + config.smallGutterX,
        startY + config.gutterY,
      )
  }

  renderBox({ items, startX, startY, line, boxHeight }) {
    if (!items || !items.length) return

    const { doc, config } = this

    this.box({ startX, startY, line, boxHeight })

    // max three items for box
    if (items.length > 3) {
      items = items.slice(0, 3)
    }

    items.forEach((item, index) => {
      this.addAnwserAndQuastion({
        title: item.question,
        subtitle: item.answer,
        startX: startX + (config.tableLimit / items.length) * index,
        startY,
      })

      // add right line
      doc.rect(
        startX + (config.tableLimit / items.length) * index,
        startY,
        line,
        boxHeight,
        'F',
      )
    })
  }

  renderMutiplesBox({ items, startX, startY, line, boxHeight }) {
    if (!items || !items.length) return

    let cursor = 0

    items.forEach((item, index) => {
      this.renderBox({
        items: item,
        startX: startX,
        startY: startY + boxHeight * index,
        line,
        boxHeight,
      })

      cursor = startY + boxHeight * index

      cursor = ReportConstructor.checkAddPage({
        cursor,
        area: boxHeight,
        doc: this.doc,
        config: this.config,
      })
    })

    return cursor
  }

  static checkAddPage({ cursor, area, doc, config }) {
    const { heightLimit, margin, tableLimit, line, startX } = config

    if (cursor + area > heightLimit) {
      doc.addPage()
      cursor = margin
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    return cursor
  }

  static getDataAtual() {
    const monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    const now = new Date()

    const str =
      'Relatório gerado em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      this.formatDate(now, 'HH:mm:ss') +
      '.'
    return str
  }

  static formatDate(date, format = 'DD/MM/YYYY') {
    return moment(date).format(format)
  }
}
