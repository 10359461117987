<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left"
      >Principais inconformidades</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" class="col-bordered">
          <span class="key-title">Inconformidades</span>
          <v-col v-if="relatorio.avaliacaoNutricional" cols="12">
            <span class="value-title">Avaliação Nutricional </span>
          </v-col>
          <v-col v-if="relatorio.melhoramentoGenTico" cols="12">
            <span class="value-title">Melhoramento Genético </span>
          </v-col>
          <v-col v-if="relatorio.avalicaoAmbienteConfortoEBemEstar" cols="12">
            <span class="value-title"
              >Avaliação Ambiente (Conforto e Bem Estar)
            </span>
          </v-col>
          <v-col v-if="relatorio.necessidadeDeInsumos" cols="12">
            <span class="value-title">Necessidade de Insumos </span>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ManejoReprodutivo',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
