<template>
  <v-card
    v-if="
      relatorio.princiapaisInconformidadesImages != null &&
      relatorio.princiapaisInconformidadesImages.length
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Imagens em anexo</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="
            relatorio.princiapaisInconformidadesImages != null &&
            relatorio.princiapaisInconformidadesImages.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens das principais inconformidades </span>
          <br />
          <v-chip
            v-for="(
              imagem, index
            ) in relatorio.princiapaisInconformidadesImages"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
export default {
  name: 'ImagensAnexo',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  mounted() {
    this.relatorio.princiapaisInconformidadesImages = JSON.parse(
      this.relatorio.princiapaisInconformidadesImages,
    )
  },

  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
