<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left"
      >Avaliação Ambiente (Conforto e Bem Estar)</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.osAnimaisFicamAlojadosEmAmbienteQuePropicieConforto"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.osAnimaisFicamAlojadosEmAmbienteQuePropicieConforto ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Os animais ficam alojados em ambiente que propicie conforto?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.oManejoDeCamaFreeStallCompostBarnEstAdequado"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.oManejoDeCamaFreeStallCompostBarnEstAdequado ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'O manejo de cama (Free stall /Compost barn) está adequado?' }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.possuiSistemaDeResfriamentoAdequadoOsAnimaisSombritesAspersOEVentilaOSuficiente
          "
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.possuiSistemaDeResfriamentoAdequadoOsAnimaisSombritesAspersOEVentilaOSuficiente ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Possui sistema de resfriamento adequado os animais (sombrites, aspersão e ventilação suficiente)?'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.aIncidNciaDeVacasMancandoAlta"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.aIncidNciaDeVacasMancandoAlta == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A incidência de vacas mancando é alta?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.possuiPeloMenos80DasVacasDeitadasApsElasSeAlimentarem"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.possuiPeloMenos80DasVacasDeitadasApsElasSeAlimentarem ==
              'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Possui pelo menos 80% das vacas deitadas após elas se alimentarem?'
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.oEspaAmentoDeCochoEstAadequado"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.oEspaAmentoDeCochoEstAadequado == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'O Espaçamento de cocho está adequado?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.possuiBebedouroNaSaDaDaOrdenha"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="relatorio.possuiBebedouroNaSaDaDaOrdenha == 'confirm'"
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Possui Bebedouro na saída da ordenha?' }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.aDisponibilidadeDeGuaParaOsAnimaisAdequada"
          cols="12"
          class="col-bordered"
        >
          <span
            v-if="
              relatorio.aDisponibilidadeDeGuaParaOsAnimaisAdequada == 'confirm'
            "
            class="mdi mdi-check-outline"
          ></span>
          <span v-else class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A disponibilidade de água para os animais é adequada?' }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
