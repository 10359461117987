var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Avaliação Nutricional")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(_vm.relatorio.fazDivisaoDasVacasPorProducaoDeLeite)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.fazDivisaoDasVacasPorProducaoDeLeite == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):(
            _vm.relatorio.fazDivisaoDasVacasPorProducaoDeLeite == 'cancel'
          )?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Faz divisão das vacas por produção de leite?')+" ")])]):_vm._e(),(_vm.relatorio.fazAcompanhamentoMensal)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.fazAcompanhamentoMensal == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):(_vm.relatorio.fazAcompanhamentoMensal == 'cancel')?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Faz acompanhamento mensal da produção de leite?')+" ")])]):_vm._e(),(_vm.relatorio.aFazendaPossuiVagaoForrageiro)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.aFazendaPossuiVagaoForrageiro == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):(_vm.relatorio.aFazendaPossuiVagaoForrageiro == 'cancel')?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('A fazenda possui vagão forrageiro?')+" ")])]):_vm._e(),(_vm.relatorio.oVagaoPossuiBalanca)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.oVagaoPossuiBalanca == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):(_vm.relatorio.oVagaoPossuiBalanca == 'cancel')?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('O Vagão possui balança?')+" ")])]):_vm._e(),(_vm.relatorio.fazOMonitoramentoDaMateriaSecaComFrequencia)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.fazOMonitoramentoDaMateriaSecaComFrequencia == 'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):(
            _vm.relatorio.fazOMonitoramentoDaMateriaSecaComFrequencia == 'cancel'
          )?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Faz o monitoramento da Matéria Seca com frequencia?')+" ")])]):_vm._e(),(_vm.relatorio.fazAnaliseBromatologicaDoVolumosoComFrequencia)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.fazAnaliseBromatologicaDoVolumosoComFrequencia ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):(
            _vm.relatorio.fazAnaliseBromatologicaDoVolumosoComFrequencia ==
            'cancel'
          )?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Faz análise bromatológica do volumoso com frequencia?')+" ")])]):_vm._e(),(_vm.relatorio.aSilagemDeMilhoSorgoCanaCapimEstaComAparenciaBoa)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.aSilagemDeMilhoSorgoCanaCapimEstaComAparenciaBoa ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):(
            _vm.relatorio.aSilagemDeMilhoSorgoCanaCapimEstaComAparenciaBoa ==
            'cancel'
          )?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('A silagem de milho/sorgo/cana/capim está com aparência boa (tamanho de partícula, sem a presença de mofo, temperatura, compactação, perdas de silo superficiais)?')+" ")])]):_vm._e(),(_vm.relatorio.osInsumoes)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.osInsumoes == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):(_vm.relatorio.osInsumoes == 'cancel')?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Os insumos (Soja, milho, minerais) estão armazenados de maneira correta e em local limpo e organizado?')+" ")])]):_vm._e(),(_vm.relatorio.ultilizaUmaSegundaFonteDeForragemNaMesmaDieta)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.ultilizaUmaSegundaFonteDeForragemNaMesmaDieta ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):(
            _vm.relatorio.ultilizaUmaSegundaFonteDeForragemNaMesmaDieta ==
            'cancel'
          )?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Utiliza uma segunda fonte de forragem na mesma Dieta?')+" ")])]):_vm._e(),(_vm.relatorio.existeSelecaoDeIngredientesDaDietaNoCochopelasVacas)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.existeSelecaoDeIngredientesDaDietaNoCochopelasVacas ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):(
            _vm.relatorio.existeSelecaoDeIngredientesDaDietaNoCochopelasVacas ==
            'cancel'
          )?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Existe seleção de ingredientes da dieta no cocho pelas vacas?')+" ")])]):_vm._e(),(_vm.relatorio.monitoraATaxaDeSobraDeComidaNoCocho)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.monitoraATaxaDeSobraDeComidaNoCocho == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):(
            _vm.relatorio.monitoraATaxaDeSobraDeComidaNoCocho == 'cancel'
          )?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Monitora a taxa de sobra de comida no cocho?')+" ")])]):_vm._e(),(_vm.relatorio.aDistribuicaoDaDietaEstaSendoRealizadaDeFormaAdequada)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.aDistribuicaoDaDietaEstaSendoRealizadaDeFormaAdequada ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):(
            _vm.relatorio.aDistribuicaoDaDietaEstaSendoRealizadaDeFormaAdequada ==
            'cancel'
          )?_c('span',{staticClass:"mdi mdi-close-outline"}):_vm._e(),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('A distribuição da dieta está sendo realizada de forma adequada? (Horário, distribuição, rotina)?')+" ")])]):_vm._e(),(_vm.relatorio.escoreCorporalEstaAdequadoNoPeriodoDeTransicao)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.escoreCorporalEstaAdequadoNoPeriodoDeTransicao ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Escore corporal está adequado no Período de Transição?')+" ")])]):_vm._e(),(
          _vm.relatorio.oNitrogenioUreicoDoLeiteNULMedioDosUltimos3MesesEstaAdequado
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.oNitrogenioUreicoDoLeiteNULMedioDosUltimos3MesesEstaAdequado ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('O Nitrogênio Ureico do leite(NUL) médio dos últimos 3 meses está adequado?')+" ")])]):_vm._e(),(_vm.relatorio.possuiOacompanhamentoMensalDeUmNutricionista)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.possuiOacompanhamentoMensalDeUmNutricionista ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Possui o acompanhamento Mensal de um Nutricionista?')+" ")])]):_vm._e(),(_vm.relatorio.apresentaGraosDeMilhoInteirosNasFezes)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.apresentaGraosDeMilhoInteirosNasFezes == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Apresenta grãos de milho inteiros nas fezes?')+" ")])]):_vm._e(),(_vm.relatorio.temCostumeDeTirarAsFaixasComMofoNoSilo)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.temCostumeDeTirarAsFaixasComMofoNoSilo == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Tem costume de tirar as faixas com mofo no silo?')+" ")])]):_vm._e(),(_vm.relatorio.temOcostumeDeLimparAsSobrasDoSilo)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.temOcostumeDeLimparAsSobrasDoSilo == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Tem o costume de limpar as sobras do silo?')+" ")])]):_vm._e(),(
          _vm.relatorio.possuiRotinaDePesarMediaAQuantidadeDeSiloAntesdeOfertarAosAnimais
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.possuiRotinaDePesarMediaAQuantidadeDeSiloAntesdeOfertarAosAnimais ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Possui a rotina de pesar/medir a quantidade de silo antes de ofertar aos animais?')+" ")])]):_vm._e(),(
          _vm.relatorio.aRetiradaDoPainelDoSiloEstaSendoFeitaDeManeiraAdequada
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.aRetiradaDoPainelDoSiloEstaSendoFeitaDeManeiraAdequada ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('A retirada do painel do silo está sendo feita de maneira adequada?')+" ")])]):_vm._e(),(_vm.relatorio.possuiDietaAnotadaEAfixadaEmLocalDeFacilAcesso)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.possuiDietaAnotadaEAfixadaEmLocalDeFacilAcesso ==
            'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Possui a dieta anotada e afixada em local de fácil acesso?')+" ")])]):_vm._e(),(_vm.relatorio.semprePesaARacaoAntesDeFornecerAosAnimais)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(
            _vm.relatorio.semprePesaARacaoAntesDeFornecerAosAnimais == 'confirm'
          )?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Sempre pesa a ração antes de fornecer aos animais?')+" ")])]):_vm._e(),(_vm.relatorio.FDNdaDietaEstAcimaDe25DaMS)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.FDNdaDietaEstAcimaDe25DaMS == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('FDN da dieta está acima de 25% da MS?')+" ")])]):_vm._e(),(_vm.relatorio.AmidoDaDietaEstEntre25A30DaMS)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.AmidoDaDietaEstEntre25A30DaMS == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('Amido da dieta está entre 25 a 30% da MS?')+" ")])]):_vm._e(),(_vm.relatorio.PBdaDietaEstEntre15E17DaMS)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.PBdaDietaEstEntre15E17DaMS == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('PB da dieta está entre 15 e 17% MS?')+" ")])]):_vm._e(),(_vm.relatorio.CNFdaDietaEstEntre35A40DaMS)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[(_vm.relatorio.CNFdaDietaEstEntre35A40DaMS == 'confirm')?_c('span',{staticClass:"mdi mdi-check-outline"}):_c('span',{staticClass:"mdi mdi-close-outline"}),_c('span',{staticClass:"value-title"},[_vm._v(" "+_vm._s('CNF da dieta está entre 35 a 40% da MS?')+" ")])]):_vm._e(),_c('span',{staticClass:"pa-3"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }