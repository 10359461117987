/* eslint-disable */
import JsPDF from 'jspdf'
import { ENV_APP } from '../../../env'
import { baseBoard } from './core/baseboard'

function renderCheckboxList(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')
}

function renderCheckbox(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  const textWidth = doc.getTextWidth(text)
  const numberLines = parseInt(textWidth / 250)

  // linha top
  doc.rect(startX, cursor, tableLimit, line, 'F')

  // linha left
  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  // add text
  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  // linha right
  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  // linha bottom
  // doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')

  if (numberLines > 1) {
    cursor += smallBoxHeight

    // linha left
    doc.rect(startX, cursor, line, smallBoxHeight, 'F')

    // linha right
    doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

    doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')
  } else {
    doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')
  }

  return cursor + smallBoxHeight
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.addPage()
    cursor = margin
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório Sólidos')

  doc.text(
    'Relatório Sólidos',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    horaInicio,
    horaTermino,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  const dataAtendimento = _ref.dataAtendimento

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + smallGutterY + boxHeight * 2,
  )

  doc.text(consultor, startX + smallGutterX, startY + gutterY + boxHeight * 2)

  doc.text(
    'Matrícula:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + smallGutterY + boxHeight,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + gutterY + boxHeight,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )
  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Data:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    dataAtendimento || '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Horário do início:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaInicio || '-',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaTermino || ' - ',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )
  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média (l/dia):',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.producaoMedia ? `${relatorio.producaoMedia}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Proteína média:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.proteinaMediaUltimoMes + ' %' || 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Gordura média:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.gorduraMediaUltimoMes + ' %' || 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'CPP média:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.cppMedia ? `${relatorio.cppMedia} UFC/ml * 10³` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.ccsMedia
      ? `${relatorio.ccsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Número de vacas em lactação:',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.numDeVacasEmLactacao
      ? relatorio.numDeVacasEmLactacao
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número de vacas secas:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.numDeVacasSecas ? relatorio.numDeVacasSecas : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F') //superior

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F') //esquerda

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F') //direita

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F') //inferior

  doc.text(
    'Número de pessoas envolvidas no manejo do rebanho:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    relatorio.numPessoasEnvolvidasNoManejoDoRebanho
      ? relatorio.numPessoasEnvolvidasNoManejoDoRebanho
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )
}

function avaliacaoNutricional(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
  } = config

  cursor += config.boxHeight + 30

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Avaliação Nutricional', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  const listItensStep = [
    {
      name: 'fazDivisaoDasVacasPorProducaoDeLeite',
      label: 'Faz divisão das vacas por produção de leite?',
    },
    {
      name: 'fazAcompanhamentoMensal',
      label: 'Faz acompanhamento mensal da produção de leite?',
    },
    {
      name: 'aFazendaPossuiVagaoForrageiro',
      label: 'A fazenda possui vagão forrageiro?',
    },
    {
      name: 'oVagaoPossuiBalanca',
      label: 'O Vagão possui balança?',
    },
    {
      name: 'fazOMonitoramentoDaMateriaSecaComFrequencia',
      label: 'Faz o monitoramento da Matéria Seca com frequencia?',
    },
    {
      name: 'fazAnaliseBromatologicaDoVolumosoComFrequencia',
      label: 'Faz análise bromatológica do volumoso com frequencia?',
    },
    {
      name: 'aSilagemDeMilhoSorgoCanaCapimEstaComAparenciaBoa',
      label:
        'A silagem de milho/sorgo/cana/capim está com aparência boa (tamanho de partícula, sem a presença de mofo, temperatura, compactação, perdas de silo superficiais)?',
    },
    {
      name: 'osInsumoes',
      label:
        'Os insumos (Soja, milho, minerais) estão armazenados de maneira correta e em local limpo e organizado?',
    },
    {
      name: 'ultilizaUmaSegundaFonteDeForragemNaMesmaDieta',
      label: 'Utiliza uma segunda fonte de forragem na mesma Dieta?',
    },
    {
      name: 'existeSelecaoDeIngredientesDaDietaNoCochopelasVacas',
      label: 'Existe seleção de ingredientes da dieta no cocho pelas vacas?',
    },
    {
      name: 'monitoraATaxaDeSobraDeComidaNoCocho',
      label: 'Monitora a taxa de sobra de comida no cocho?',
    },
    {
      name: 'aDistribuicaoDaDietaEstaSendoRealizadaDeFormaAdequada',
      label:
        'A distribuição da dieta está sendo realizada de forma adequada? (Horário, distribuição, rotina)?',
    },
    {
      name: 'escoreCorporalEstaAdequadoNoPeriodoDeTransicao',
      label: 'Escore corporal está adequado no Período de Transição?',
    },
    {
      name: 'oNitrogenioUreicoDoLeiteNULMedioDosUltimos3MesesEstaAdequado',
      label:
        'O Nitrogênio Ureico do leite(NUL) médio dos últimos 3 meses está adequado?',
    },
    {
      name: 'possuiOacompanhamentoMensalDeUmNutricionista',
      label: 'Possui o acompanhamento Mensal de um Nutricionista?',
    },
    {
      name: 'apresentaGraosDeMilhoInteirosNasFezes',
      label: 'Apresenta grãos de milho inteiros nas fezes?',
    },
    {
      name: 'temCostumeDeTirarAsFaixasComMofoNoSilo',
      label: 'Tem costume de tirar as faixas com mofo no silo?',
    },
    {
      name: 'temOcostumeDeLimparAsSobrasDoSilo',
      label: 'Tem o costume de limpar as sobras do silo?',
    },
    {
      name: 'possuiRotinaDePesarMediaAQuantidadeDeSiloAntesdeOfertarAosAnimais',
      label:
        'Possui a rotina de pesar/medir a quantidade de silo antes de ofertar aos animais?',
    },
    {
      name: 'aRetiradaDoPainelDoSiloEstaSendoFeitaDeManeiraAdequada',
      label:
        'A retirada do painel do silo está sendo feita de maneira adequada?',
    },
    {
      name: 'possuiDietaAnotadaEAfixadaEmLocalDeFacilAcesso',
      label: 'Possui a dieta anotada e afixada em local de fácil acesso?',
    },
    {
      name: 'semprePesaARacaoAntesDeFornecerAosAnimais',
      label: 'Sempre pesa a ração antes de fornecer aos animais?',
    },
    {
      name: 'FDNdaDietaEstAcimaDe25DaMS',
      label: 'FDN da dieta está acima de 25% da MS?',
    },
    {
      name: 'AmidoDaDietaEstEntre25A30DaMS',
      label: 'Amido da dieta está entre 25 a 30% da MS?',
    },
    {
      name: 'PBdaDietaEstEntre15E17DaMS',
      label: 'PB da dieta está entre 15 e 17% MS?',
    },
    {
      name: 'CNFdaDietaEstEntre35A40DaMS',
      label: 'CNF da dieta está entre 35 a 40% da MS?',
    },
  ]

  listItensStep.forEach(item => {
    if (relatorio[item.name] == 'confirm') {
      cursor = renderCheckbox(doc, `(V) ${item.label}`, cursor, config)
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else {
      cursor = renderCheckbox(doc, `(X) ${item.label}`, cursor, config)
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
  })

  return cursor
}

function melhoramentoGenetico(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
  } = config

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Melhoramento Genético', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  const listItensStep = [
    {
      name: 'possuiAlgunsDosProgramasDeMelhoramentoGenTico',
      label: 'Possui algum plano genético na propriedade?',
    },
    {
      name: 'aPropriedadeUtilizaInseminaOArtificial',
      label: 'A propriedade utiliza inseminação artificial?',
    },
    {
      name:
        'aFazendaTemCostumeDeUtilizarDentroDoSeuPlanejamentoGenTicoTourosComAptidOParaSOlidosPositivo',
      label:
        'A fazenda tem costume de utilizar dentro do seu planejamento genético touros com aptidão para sólidos positivo?',
    },
  ]

  listItensStep.forEach(item => {
    if (relatorio[item.name] == 'confirm') {
      cursor = renderCheckbox(doc, `(V) ${item.label}`, cursor, config)
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else {
      cursor = renderCheckbox(doc, `(X) ${item.label}`, cursor, config)
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
  })

  return cursor
}

function avaliacaoAmbiente(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
  } = config

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Avaliação Ambiente (Conforto e Bem Estar)', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  const listItensStep = [
    {
      name: 'osAnimaisFicamAlojadosEmAmbienteQuePropicieConforto',
      label: 'Os animais ficam alojados em ambiente que propicie conforto?',
    },
    {
      name: 'oManejoDeCamaFreeStallCompostBarnEstAdequado',
      label: 'O manejo de cama (Free stall /Compost barn) está adequado?',
    },
    {
      name:
        'possuiSistemaDeResfriamentoAdequadoOsAnimaisSombritesAspersOEVentilaOSuficiente',
      label:
        'Possui sistema de resfriamento adequado os animais (sombrites, aspersão e ventilação suficiente)?',
    },
    {
      name: 'aIncidNciaDeVacasMancandoAlta',
      label: 'A incidência de vacas mancando é alta?',
    },
    {
      name: 'possuiPeloMenos80DasVacasDeitadasApsElasSeAlimentarem',
      label:
        'Possui pelo menos 80% das vacas deitadas após elas se alimentarem?',
    },
    {
      name: 'oEspaAmentoDeCochoEstAadequado',
      label: 'O Espaçamento de cocho está adequado?',
    },
    {
      name: 'possuiBebedouroNaSaDaDaOrdenha',
      label: 'Possui Bebedouro na saída da ordenha?',
    },
    {
      name: 'aDisponibilidadeDeGuaParaOsAnimaisAdequada',
      label: 'A disponibilidade de água para os animais é adequada?',
    },
  ]

  listItensStep.forEach((item, index) => {
    if (relatorio[item.name] == 'confirm') {
      cursor = renderCheckbox(doc, `(V) ${item.label}`, cursor, config)
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else {
      cursor = renderCheckbox(doc, `(X) ${item.label}`, cursor, config)
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
  })

  return cursor
}

function necessidadeInsumos(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
  } = config

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Necessidade de Insumos', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  const listItensStep = [
    {
      name: 'aPropriedadePossuiVolumosoDeQualidadeParaOAnoTodo',
      label: 'A propriedade possui volumoso de qualidade para o ano todo?',
    },
    {
      name: 'aPropriedadeFazCompraEstratGicaDeInsumos',
      label: 'A propriedade faz compra estratégica de insumos?',
    },
  ]

  listItensStep.forEach((item, index) => {
    if (relatorio[item.name] == 'confirm') {
      cursor = renderCheckbox(doc, `(V) ${item.label}`, cursor, config)
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else {
      cursor = renderCheckbox(doc, `(X) ${item.label}`, cursor, config)
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
  })

  return cursor
}

function listDasPrincipaisConformidades(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    line,
    startX,
    tableLimit,
    margin,
    heightLimit,
    boxHeight,
    mediumFontSize,
    fontSize,
  } = config

  cursor += config.boxHeight

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais inconformidades', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

  doc.rect(startX, cursor, tableLimit, line, 'F')
  doc.setFont('helvetica', 'bold')
  renderCheckboxList(doc, ' • Inconformidades', cursor, config)
  doc.setFont('helvetica', 'normal')
  cursor += smallBoxHeight
  cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

  if (relatorio.avaliacaoNutricional) {
    renderCheckboxList(doc, 'Avaliação Nutricional', cursor, config)
    cursor += smallBoxHeight
  }

  if (relatorio.melhoramentoGenTico) {
    renderCheckboxList(doc, 'Melhoramento Genético', cursor, config)
    cursor += smallBoxHeight
  }

  if (relatorio.avalicaoAmbienteConfortoEBemEstar) {
    renderCheckboxList(
      doc,
      'Avaliação Ambiente (Conforto e Bem Estar)',
      cursor,
      config,
    )
    cursor += smallBoxHeight
  }

  if (relatorio.necessidadeDeInsumos) {
    renderCheckboxList(doc, 'Necessidade de Insumos', cursor, config)
    cursor += smallBoxHeight
  }

  doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.rect(startX, cursor, tableLimit, line, 'F')

  return cursor
}

function renderRecomendacoes(doc, text, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data || '-'
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 2)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.setFont('helvetica', 'normal')
  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.text(data, startX + smallGutterX, cursor + gutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

function planodeAcao(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
  } = config

  cursor += config.boxHeight

  if (relatorio.planoDeAcao && relatorio.planoDeAcao.trim() !== '') {
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Planos de ação', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = renderRecomendacoes(doc, '', relatorio.planoDeAcao, cursor, config)
  }

  return cursor
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    tableLimit,
    heightLimit,
    margin,
    startX,
    smallGutterY,
    line,
    smallBoxHeight,
  } = config

  cursor += config.boxHeight
  if (cursor + boxHeight * 7 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  cursor += boxHeight * 2

  const proximaVisita = relatorio.assinaturaProdutorOuResponsavel
    ? relatorio.dataProximaVisita
    : moment(relatorio.dataProximaVisita).format('DD/MM/YYYY')

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  const produtor = new Image()

  if (relatorio.assinaturaProdutorOuResponsavel) {
    if (relatorio.assinaturaProdutorOuResponsavel === '1') {
      produtor.src = relatorio.assinaturaProdutor
      doc.addImage(
        produtor,
        'PNG',
        startX + 50,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    } else {
      produtor.src = relatorio.assinaturaResponsavel
      doc.addImage(
        produtor,
        'PNG',
        startX + 50,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    const consultor = new Image()
    consultor.src = relatorio.assinaturaConsultor

    if (relatorio.assinaturaConsultor) {
      doc.addImage(
        consultor,
        'PNG',
        tableLimit - 180,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

    if (relatorio.assinaturaProdutorOuResponsavel === '1') {
      doc.text(
        relatorio.fazenda.produtor.Nome,
        tableLimit / 3.5,
        cursor + 100 + smallGutterY,
        null,
        null,
        'center',
      )
      doc.text(
        'Assinatura produtor(a)',
        tableLimit / 3.5,
        cursor + 115 + smallGutterY,
        null,
        null,
        'center',
      )
    } else {
      doc.text(
        relatorio.nomeResponsavel || 'Não informado',
        tableLimit / 3.5,
        cursor + 100 + smallGutterY,
        null,
        null,
        'center',
      )
      doc.text(
        'Assinatura do responsável',
        tableLimit / 3.5,
        cursor + 115 + smallGutterY,
        null,
        null,
        'center',
      )
    }

    doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
    doc.text(
      relatorio.consultor.pessoafisica.Nome,
      startX + 420,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura consultor(a)',
      startX + 420,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    produtor.src = relatorio.assinaturas.assinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )

    const consultor = new Image()
    consultor.src = relatorio.assinaturas.assinaturaConsultor
    if (relatorio.assinaturas.assinaturaConsultor) {
      doc.addImage(
        consultor,
        'PNG',
        tableLimit - 180,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    doc.rect(startX + 25, cursor + 100, tableLimit / 3, line, 'F')
    doc.text(
      relatorio.assinaturas.NomeResponsavel
        ? relatorio.assinaturas.NomeResponsavel
        : relatorio.fazenda.produtor.Nome,
      startX + 65,
      cursor + 100 + smallGutterY,
    )
    doc.text(
      'Assinatura ' +
        (relatorio.assinaturas.NomeResponsavel
          ? 'do responsável'
          : 'do(a) produtor(a)'),
      startX + 65,
      cursor + 115 + smallGutterY,
    )

    doc.rect(startX + 320, cursor + 100, tableLimit / 3, line, 'F')
    doc.text(
      relatorio.consultor.pessoafisica.Nome,
      startX + 360,
      cursor + 100 + smallGutterY,
    )
    doc.text(
      'Assinatura consultor(a)',
      startX + 360,
      cursor + 115 + smallGutterY,
    )
  }

  cursor += boxHeight * 2

  return cursor
}

function imagesAnexo(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config
  if (
    relatorio.princiapaisInconformidadesImages &&
    relatorio.princiapaisInconformidadesImages.length > 0
  ) {
    cursor += boxHeight * 4
    if (cursor + boxHeight * 10 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Imagens em anexo', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    let colInicio = startX

    if (
      relatorio.princiapaisInconformidadesImages &&
      relatorio.princiapaisInconformidadesImages.length > 0
    ) {
      if (cursor + boxHeight * 6 > heightLimit) {
        doc.addPage()
        cursor = margin + 10
      }

      try {
        doc
          .setFont('helvetica', 'bold')
          .text('Imagens das principais inconformidades', startX, cursor)
          .setFont('helvetica', 'normal')

        cursor += smallBoxHeight

        let imagem = new Image()
        Object.keys(relatorio.princiapaisInconformidadesImages).forEach(
          item => {
            if (item % 2 === 1) {
              colInicio = startX + 10 + tableLimit / 2
            } else {
              colInicio = startX + 40
            }

            if (cursor + boxHeight * 8 > heightLimit) {
              doc.addPage()
              cursor = margin + 10
            }

            imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.princiapaisInconformidadesImages[item].id}`

            doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

            if (
              item % 2 === 1 ||
              Object.keys(relatorio.princiapaisInconformidadesImages).length -
                1 ==
                item
            ) {
              cursor += 200
            }
          },
        )
        cursor += boxHeight
      } catch (error) {}
    }

    colInicio = startX
  }

  return cursor
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95

    const doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })

    const config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      let cursor = 0
      header(doc, config, data)
      subHeader(doc, data, config)
      config.startY = config.startY + config.boxHeight + 120
      cursor = config.startY
      indicadoresQualidade(doc, data, config)
      config.startY = config.startY + config.boxHeight + 100
      cursor = config.startY
      cursor = avaliacaoNutricional(doc, data, config, cursor)
      cursor += config.boxHeight
      cursor = melhoramentoGenetico(doc, data, config, cursor)
      cursor += config.boxHeight
      cursor = avaliacaoAmbiente(doc, data, config, cursor)
      cursor += config.boxHeight
      cursor = necessidadeInsumos(doc, data, config, cursor)
      cursor += config.boxHeight
      cursor = listDasPrincipaisConformidades(doc, data, config, cursor)
      cursor += config.boxHeight
      planodeAcao(doc, data, config, cursor)
      cursor += config.boxHeight
      cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
      cursor += config.boxHeight
      imagesAnexo(doc, data, config, cursor)

      config.startY = cursor + config.boxHeight - 3
      const pageCount = doc.internal.getNumberOfPages()

      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        baseBoard(
          doc,
          config,
          cursor + config.boxHeight,
          String(i) + ' de ' + String(pageCount),
        )
      }
      doc
        .save(
          `relatorio-solidos-${data.fazenda.pessoajuridica.NomeFantasia}-${data.dataAtendimento}.pdf`,
          {
            returnPromise: true,
          },
        )
        .then(() => {
          return resolve(true)
        })
    } catch (err) {
      reject(err)
    }
  })
}
